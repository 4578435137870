











































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminVideos } from '@/store/admin/getters';
import { dispatchGetVideos, dispatchDeleteVideo } from '@/store/admin/actions';

@Component
export default class AdminVideos extends Vue {
  public search = '';

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Link/ID',
      sortable: true,
      value: 'reference',
      align: 'left',
    },
    {
      text: 'Views',
      sortable: true,
      value: 'views',
      align: 'left',
    },
    {
      text: 'Like Score (Avg) / 100',
      sortable: true,
      value: 'liked_avg',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
    },
  ];
  get videos() {
    return readAdminVideos(this.$store);
  }

  public async deleteVideo(videoID) {
    await dispatchDeleteVideo(this.$store, videoID);
  }

  public async mounted() {
    await dispatchGetVideos(this.$store);
  }
}
